.pm-progress-bar {
    height: 4px;
}

.outer-ring {
    width: 24px;
    height: 24px;
}

.pm-progress-bar .progress-bar {
    animation-name: progress-animation;
    animation-duration: 1s;
    animation-delay: -1s;
    animation-timing-function: ease-in-out;
}

@keyframes progress-animation {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}