.carousel_component {
    /*height: 26vw;*/
}

.carousel-indicators [data-bs-target] {
    width: 12px !important;
    height: 12px !important;
    border-radius: 12px !important;
    border: 1px;
}

@media only screen and (max-width : 768px) {
    .carousel_component {
        /*height: 28vh;*/
    }
}