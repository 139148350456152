.sell_your_bicycle_box {
    margin-top: -10vh;
    z-index: 1;
}

.carousel-indicators {
    margin-bottom: 12vh;
}

@media only screen and (max-width : 768px) {
    .carousel-indicators {
        margin-bottom: 1rem;
    }

    .sell_your_bicycle_box {
        margin-top: 2vh;
        width: 100%;
    }
}

.scroll-element {
    position: absolute;
}

.primary {
    animation: primary 26s linear infinite;
}

.secondary {
    animation: secondary 26s linear infinite;
}

@keyframes primary {
    from {
        transform: translate(0,0);
    }
    to {
        transform: translate(-100%,0);
    }
}

@keyframes secondary {
    from {
        transform: translate(100%,0);
    }
    to {
        transform: translate(0,0);
    }
}